import React from 'react';


function About() {
    return (
        <div className="About">
            <p>

                <b>Статусы и регалии:</b><br />
                Председатель правления Ассоциации «Соль» (специализируется на работе с людьми, совершающими насилие в близких отношениях), член Ассоциации когнитивно-поведенческой психотерапии, член Ассоциации EMDR-терапевтов, член Сообщества эмоционально-фокусированных терапевтов (СЭФТ и ICEEFT). Аккредитованный супервизор ОППЛ.<br />
                Мой опыт работы: <br />
                2006-2008 – Городская Психотерапевтическая Поликлиника №223, отделений психотерапии в чрезвычайных ситуациях, клинический психолог<br />
                2008-2009 – Психоневрологический диспансер №19, клинический психолог.<br />
                2010 по н.в. - частная практика в качестве клинического психолога, индивидуальная и групповая работа.<br />
                2015-2018. - психолог благотворительного фонда "Семья и детство".<br />
                2019 по 2021 - психолог-волонтер горячей линии для детей и подростков "Помощь рядом"<br />
                2020 по 2022 – клиника Доктора Исаева, клинический психолог, патодиагностика.<br />
                2018 по н.в. - медико-психологический центр "Белтико", психолог<br />
                2020 по н.в. – АНО "Мужчины 21в", менеджер проектов, психолог<br />
                2020 по н.в. – ООО "Невская Альтернатива", клинический психолог<br />
                2022 по н.в. - председатель Ассоциации "Соль"<br />
                <br />
                <br />
                <b>Мое образование:</b><br />
                <br />
                <i>Базовое образование:</i><br />
                2006-2008 Московский Педагогический Государственный Университет (очная форма). Магистратура. Направление – психология. Специализация – психология развития.<br />
                2004-2006 ГОУ ВПО Российский Государственный Медицинский Университет Росздрава (вечерняя форма) Профессиональная переподготовка по медицинской (клинической) психологии.<br />
                2000-2004 Современная Гуманитарная Академия (очная форма). Бакалавриат. Направление – психология.<br />
                <br />
                <i>Дополнительное образование:</i><br />
                Непрерывное обучение по настоящее время, чтобы не перезагружать информацией, все подробности вынесены во вкладку с юридическими аспектами.<br />
                Прохожу регулярно супервизии и интервизии, веду активную профессиональную научную жизнь в области работы с совершающими насилие в близких отношениях и травматерапии и парной терапии, личная терапия – более 150 часов. 

            </p>
        </div>
    );
  }

export default About;

    